import { 
    IonHeader, 
    IonButtons, 
    IonToolbar,
    IonBackButton, 
    IonTitle,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonIcon,
    IonMenuToggle,
    IonGrid,
    IonRow,
    IonCol,
    IonChip,
    IonRefresher,
    IonRefresherContent,
    IonList,
    IonFab,
    IonFabButton,
    IonText,
    IonSearchbar,
    IonRadio,
    IonAvatar,
    IonItemOption,
    IonItemOptions,
    IonSelectOption,
    IonItemSliding,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonItemGroup,
    IonItemDivider,
    IonBadge,
    IonSegment,
    IonSegmentButton,
    IonThumbnail,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonImg,
    IonCardSubtitle,
    IonTabButton,
    IonTabBar,
    IonFooter,
    IonFabList,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonTabs,
    IonRouterOutlet,
    IonToggle,
    IonSelect,
    IonTextarea,
    IonReorder,
    IonReorderGroup
} from '@ionic/angular/standalone';
export const importsStandalone = [
    IonHeader,
    IonButtons,
    IonToolbar,
    IonBackButton,
    IonTitle,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonIcon,
    IonMenuToggle,
    IonRow,
    IonCol,
    IonGrid,
    IonChip,
    IonRefresher,
    IonRefresherContent,
    IonList,
    IonFab,
    IonFabButton,
    IonText,
    IonTextarea,
    IonSearchbar,
    IonRadio,
    IonAvatar,
    IonItemOption,
    IonItemOptions,
    IonSelectOption,
    IonItemSliding,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonItemGroup,
    IonItemDivider,
    IonBadge,
    IonSegment,
    IonSegmentButton,
    IonThumbnail,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonImg,
    IonTabButton,
    IonTabBar,
    IonFooter,
    IonFabList,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonTabs,
    IonRouterOutlet,
    IonToggle,
    IonSelect,
    IonReorder,
    IonReorderGroup
];